<template>
  <div>
    <div style="height: 220px;">
      <!-- <img
        src="https://api.map.baidu.com/staticimage/v2?ak=kMG4a7gZ5YTYShqSAuCBMknT3T3VMYkI&mcode=666666&center=116.452339,39.91344&width=300&height=200&zoom=11"
        alt="图片"
      /> -->
      <MapPolyline
        v-if="showLuShu"
        :start="startTrailPlace"
        :end="endTrailPlace"
        :trails="trails"
        :landmarkPois="landmarkPois"
      />
      <Map
        v-show="!showLuShu"
        ref="mapRef"
        @choosedLocation="choosedLocation"
        @drawLine="drawLine"
      />
      <div style="text-align: center;padding-right: 20px;margin-top: 20px;">
        <div v-if="showDis">
          距离您约 <font class="ft-cls">{{ distance }}</font>
        </div>
        <!-- <div style="margin-top: 10px;">
          预估约 <font class="ft-cls">{{ estimateTime }}</font
          >到达
        </div> -->
        <div
          v-if="showDis"
          style="font-size: 12px;margin-top: 10px;color: #b0aeae;text-align: center;"
        >
          救援师傅正快马加鞭赶来，请耐心等待
        </div>
      </div>
      <div style="padding: 10px;margin-top: 20px;margin-bottom: 120px;">
        <van-row style="text-align: center;">
          <!-- <van-col :span="24" style="margin-bottom: 20px;">
            <img
              style="height: 50px;width: 180px;"
              :src="hotlineJPG"
              @click="callCsPhone"
            />
          </van-col> -->
          <van-col :span="24">
            <div
              style="font-size: 12px;margin-top: 10px;color: #b0aeae;text-align: center;margin-bottom: 10px;"
            >
              联系救援师傅
            </div>
          </van-col>
          <van-col
            :span="24"
            style="display: flex;justify-content: center;margin-bottom: 80px;"
          >
            <div style="height: 50px;width: 180px;">
              <van-col :span="12" style="text-align: left;">
                <van-image
                  style="width: 50px;height: 50px;"
                  :src="contactJPG"
                  @click="callDriverPhone"
                />
              </van-col>
              <van-col :span="12" style="text-align: right;">
                <div
                  style="margin-bottom: 5px;color: #12851f;text-align: right;"
                >
                  {{ order.driverName }}
                </div>
                <div style="color: #12851f;text-align: right;">
                  {{ order.dispatchPlateNo }}
                </div>
              </van-col>
            </div>
          </van-col>
          <van-col :span="24">
            <div
              style="font-size: 12px;margin-top: 10px;color: #b0aeae;text-align: center;"
            >
              客服电话：<a @click="callCsPhone">400-0623-400</a>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Map from "@/components/Map";
import MapPolyline from "@/components/MapPolyline";
import { request } from "@/api/service";
import { dictFilter } from "@/utils/dictUtils";
import hotlineJPG from "@/assets/images/ui/hotline.jpg";
import contactJPG from "@/assets/images/ui/contact.jpg";
export default {
  name: "ViewDriver",
  components: { Map, MapPolyline },
  computed: {
    baiduMapKey() {
      return process.env.VUE_APP_BAIDU_MAP_KEY;
    },
  },
  data() {
    return {
      showDis: false,
      startTrailPlace: {},
      endTrailPlace: {},
      landmarkPois: [],
      trails: [],
      showLuShu: false,
      hotlineJPG,
      contactJPG,
      id: null,
      startPlace: null,
      startLng: null,
      startLat: null,
      endPlace: null,
      endLng: null,
      endLat: null,
      distance: null,
      estimateTime: null,
      csPhone: "4000623400",
      order: {
        id: null,
        driverName: null,
        driverPhone: null,
        dispatchPlateNo: "",
        chargeMark: null,
      },
      detail: {},
      timer: null,
    };
  },
  created() {
    this.init();
    this.setDict();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
  methods: {
    ...mapActions("dict", ["setDict"]),
    async init() {
      const { id } = this.$route.query;
      const detail = await request({
        url: `/acs-rescue/order/feedback/orderInfo`,
        method: "post",
        data: {
          orderId: id,
        },
      });
      if (!detail) return;
      this.detail = detail;
      this.detail.orderType = detail.order.orderType;
      if (this.detail.orderType !== "1") return;
      // 通过id查询订单详情，初始化订单及终点
      this.id = id;
      this.startPlace = detail.rescueAddr;
      this.startLng = detail.rescueLongitude;
      this.startLat = detail.rescueLatitude;
      if (detail.dispatch) {
        this.order = {
          id,
          settleType: dictFilter(detail.settleType, "RSC_SETTLE_TYPE"),
          dispatchPlateNo: detail.dispatch.plateNo,
          driverPhone: detail.dispatch.driverMobile,
          driverName: detail.dispatch.driverName,
          charges:
            detail.dispatch.priceType === "1"
              ? (detail.dispatch.price || "0.00") + "元"
              : detail.dispatch.price +
                "元 含" +
                detail.dispatch.mileage +
                "公里，超出后" +
                detail.dispatch.pricingMileage +
                "元/公里",
        };
      }
      // 如果订单完成则初始化路书
      if (
        detail.dispatch &&
        this.detail.order &&
        this.detail.order.orderStatus === "8" &&
        this.detail.order.driverStatus === "12"
      ) {
        // 查询司机全部轨迹
        const res = await request({
          url: `/acs-rescue/order/driverTrail/all`,
          method: "post",
          data: {
            orderId: this.detail.orderId,
            driverId: this.detail.driverId,
          },
        });
        if (!res || !res.length) return;
        this.startTrailPlace = {
          lng:
            this.detail.order.projectCode &&
            this.detail.order.projectCode.charAt(0) === "T"
              ? this.detail.rescueLongitude
              : this.detail.departureLongitude,
          lat:
            this.detail.order.projectCode &&
            this.detail.order.projectCode.charAt(0) === "T"
              ? this.detail.rescueLatitude
              : this.detail.departureLatitude,
          address:
            this.detail.order.projectCode &&
            this.detail.order.projectCode.charAt(0) === "T"
              ? this.detail.rescueAddress
              : this.detail.departureAddress,
        };
        this.endTrailPlace = {
          lng:
            this.detail.order.projectCode &&
            this.detail.order.projectCode.charAt(0) === "T"
              ? this.detail.destinationLongitude
              : this.detail.rescueLongitude,
          lat:
            this.detail.order.projectCode &&
            this.detail.order.projectCode.charAt(0) === "T"
              ? this.detail.destinationLatitude
              : this.detail.rescueLatitude,
          address:
            this.detail.order.projectCode &&
            this.detail.order.projectCode.charAt(0) === "T"
              ? this.detail.destinationAddress
              : this.detail.rescueAddress,
        };
        this.trails = res
          .filter((r) =>
            this.detail.order.projectCode &&
            this.detail.order.projectCode.charAt(0) === "T"
              ? r.type === "1"
              : r.type === "0"
          )
          .map((r) => {
            return {
              id: r.id,
              lat: r.latitude,
              lng: r.longitude,
            };
          });
        this.landmarkPois = [
          {
            lng: this.detail.rescueLongitude,
            lat: this.detail.rescueLatitude,
            html: "救援地",
            pauseTime: 1,
          },
        ];
        this.showLuShu = true;
      }
    },
    callCsPhone() {
      window.location.href = "tel:" + this.csPhone;
    },
    callDriverPhone() {
      if (this.order.driverPhone)
        window.location.href = "tel:" + this.order.driverPhone;
    },
    async driverLine() {
      const detail = await request({
        url: `/acs-rescue/order/feedback/orderInfo`,
        method: "post",
        data: {
          orderId: this.id,
        },
      });
      if (!detail) return;
      this.detail = detail;
      if (
        this.detail &&
        this.detail.order &&
        this.detail.order.orderType !== "1"
      ) {
        return;
      }
      // 查询司机最新轨迹信息
      if (
        this.detail.order &&
        this.detail.order.orderStatus === "4" &&
        this.detail.order.driverStatus === "1"
      ) {
        this.showDis = true;
        const trail = await request({
          url: `/acs-rescue/order/driverTrail/latest`,
          method: "post",
          data: {
            orderId: this.detail.orderId,
            driverId: this.detail.driverId,
          },
        });
        if (!trail) return;
        if (trail.address) {
          this.endPlace = trail.address;
        }
        this.endLng = trail.longitude;
        this.endLat = trail.latitude;
        // 规划路线
        this.$refs.mapRef.drawLine(
          {
            lng: this.startLng,
            lat: this.startLat,
          },
          {
            lng: this.endLng,
            lat: this.endLat,
          }
        );
      }
    },
    choosedLocation({ lng, lat, addr }) {
      // this.startPlace = addr;
      // this.startLng = lng;
      // this.startLat = lat;
      this.driverLine();
      this.timer = setInterval(() => {
        // 需要定时执行的代码
        this.driverLine();
      }, 1000 * 60);
      // Mock
      // this.$refs.mapRef.trailPlay()
    },
    drawLine({ time, lenth }) {
      this.distance = Math.round(lenth.replace("公里", "")) + "公里";
      this.estimateTime = time;
    },
  },
};
</script>
<style lang="scss" scoped>
.ft-cls {
  color: #12851f;
  font-weight: bold;
  font-size: 20px;
}
</style>
